@import './src/assets/scss/breakpoints';

.club-top {
  margin-bottom: 6rem;

  &__body {
    display: flex;
    align-items: center;
  }

  &__map.map {
    flex-basis: 87rem;
    margin-right: 3rem;
    height: 34.5rem;
  }

  &__items {
    flex-basis: 26.5rem;
  }

  &__item {
    display: flex;
    margin-bottom: 5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &-svg {
      //background: var(--color-white-1);
      border-radius: 50%;
      width: 4.5rem;
      height: 4.5rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.8rem;

      color: var(--color-orange);
      border: 1px solid var(--color-orange);

      svg {
        width: 40%;
        height: 40%;
      }
    }

    &-title {
      font-weight: 600;
      line-height: 145%;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    &-title,
    &-subtitle {
      font-size: 1.6rem;
    }

    &-subtitle {
      display: block;
      font-weight: 300;
      line-height: 125%;
    }
  }

  @media (max-width: $md) {
    margin-top: 2rem;
    margin-bottom: 4.5rem;

    &__body {
      display: block;
    }

    &__items {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: wrap;
    }

    &__map.map {
      position: relative;
      width: 100vw;
      margin-top: 3.5rem;
      margin-right: -2.5rem ;
      margin-left: -2.5rem;
    }

    &__item {
      margin-bottom: 2.5rem;
      margin-right: 5rem;

      &-svg {
        display: none;
      }

      &-title {
        font-size: 1.2rem;
        margin-bottom: .3rem;
      }

      &-subtitle {
        font-size: 1.5rem;
        line-height: 2.2rem;
      }
    }

    &__link-maps {
      font-style: italic;
      font-size: 1.4rem;
      text-decoration: underline;

      margin-top: .4rem;
    }

  }
}
