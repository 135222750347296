@function breakpoint-max($name) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max, null);
}

@mixin media-down($name) {
  $max: breakpoint-max($name);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
@mixin clipTextHeight($lineHeight, $row) {
  line-height: $lineHeight * 1em;
  max-height: $lineHeight * $row * 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

@mixin resetBtn() {
  font: inherit;
  border: 0;
  outline: 0;
  padding: 0;
  text-decoration: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  text-align: left;
  word-break: break-word;
}


@mixin resetList() {
  font: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin clipText() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

@mixin customScroll() {
  @supports (-webkit-overflow-scrolling: touch) {
    -webkit-overflow-scrolling: touch
  }

  @supports (scrollbar-width: thin) {
    scrollbar-color: var(--color-white-1) white;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: .5rem;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-white-1);
  }

  &::-webkit-resizer,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner {
    display: none;
  }
}