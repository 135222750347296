@import '../../../assets/scss/breakpoints';

.home-blog {
  margin: 11rem 0 9rem;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  &__title {
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 125%;
    text-transform: uppercase;
  }

  &__bottom {
    display: flex;

    & .swiper-wrapper {
      width: 100%;
    }

    &-item {
      flex-shrink: 0;
      width: calc((100% - 3rem * 2) / 3);
      margin-right: 3rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__slider {
    position: relative;
    max-width: 60rem;
    width: 100%;
    margin-bottom: 4.5rem;
  }

  &__slide {
    flex: 0 0 100%;
  }

  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: auto;

    &-prev {
      left: -3.6rem;

      svg {
        transform: translateX(1rem) rotate(-180deg) !important;
      }
    }

    &-next {
      right: -3.6rem;

      svg {
        transform: translateX(-1rem);
      }
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 57%;
    width: 100%;
    pointer-events: none;
  }

  @media (max-width: $md) {
    margin: 6rem 0 6rem;

    .container {
      padding: 0;
    }

    &__slider {
      margin-bottom: 2.7rem;
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__top {
      padding: 0 3rem;
      justify-content: center;
      margin-bottom: 1.8rem;
    }

    &__title {
      font-size: 1.8rem;
    }
  }
}
