@import '../../assets/scss/breakpoints';
@import '../../assets/scss/mixins';

.header {
  background: black;
  padding: 4.5rem 0;

  &__body {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: 2.5rem;

    svg {
      width: 11.8rem;
      max-height: 6rem;
    }
  }

  &__text {
    color: var(--color-grey);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 160%;
    text-transform: uppercase;

    span {
      display: block;
      text-align: right;
    }

    &._mw {
      max-width: 17rem;
      padding-top: .7rem;
    }

    &._ml {
      margin-left: 3.5rem;
    }
  }

  &__nav {
    display: flex;
    column-gap: 3rem;
    margin-left: 5rem;
    margin-right: auto;
  }

  &__link {
    position: relative;
    color: var(--color-white);
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.7rem;
    text-transform: uppercase;

    transition-property: color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

    &::before {
      content: '';
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      top: -1.7rem;
      left: 50%;
      transform: translateX(-50%) scale(0);
      background: var(--color-orange);
      border-radius: 50%;
      opacity: 0;
      box-shadow: var(--box-shadow);

      transition-property: opacity, transform;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }

    &:hover {
      color: var(--color-orange);
    }

    &.active {
      color: var(--color-orange);
      cursor: default;

      &::before {
        opacity: 1;
        transform: translateX(-50%) scale(1);
      }
    }
  }

  &__burger {
    width: 4.6rem;
    height: 4.6rem;
    background-color: transparentize(white, 0.9);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--color-orange);
      width: 2rem;
      height: 1.4rem;
    }
  }

  @media (max-width: $xl) {
    &__nav {
      margin-left: 5rem;
    }
  }

  @media (max-width: $lg) {
    &__logo {
      margin-right: 0;
    }
  }

  @media (max-width: $md) {
    padding: 3.2rem 0 2.9rem;

    &__logo {
      margin-left: 1rem;
    }

    &__mobile {
      &-back {
        @include resetBtn;
        width: 4.6rem;
        height: 4.6rem;

        border-radius: 50%;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-left: auto;
        margin-right: 1.5rem;

        color: var(--color-orange);
        background-color: fade-out(white, .9);

        svg {
          width: 24%;
          height: 24%;

          transform: scale(-1);
        }
      }
    }

    &__body {
      justify-content: space-between;
    }
  }
}
