@import './src/assets/scss/mixins';

.bread-crumbs {
  margin-bottom: 6rem;

  &__list {
    @include resetList;
    display: flex;
  }

  &__item {
    display: inline-block;
    white-space: nowrap;

    &:not(:last-child) {
      &:after {
        content: '>';
        margin: 0 1rem;
      }
    }

    &:last-child &-text {
      color: var(--color-grey);
    }

    &-text {
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }
}
