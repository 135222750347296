@import './src/assets/scss/breakpoints';

.trainer-social {
  display: flex;
  align-items: center;

  &__left {
    display: flex;
    align-items: center;
  }

  &__link {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
    color: white;
    background: var(--color-orange);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;

    img,
    svg {
      width: 2.2rem;
      height: 2.2rem;
    }

    &._phone {
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  &__phone {
    font-weight: 300;
    font-size: 2rem;
    line-height: 145%;
    text-transform: uppercase;
  }

  @media (max-width: $md) {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 2rem;

    &__phone {
      margin-bottom: 2rem;
    }
  }
}
