@import './src/assets/scss/breakpoints';
@import './src/assets/scss/utils';

.promo {
  position: relative;
  color: white;
  background: black;
  overflow: hidden;

  margin-bottom: 3.5rem;

  margin-top: -15rem;
  padding-top: 15rem;

  &__inner {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 2rem;

    padding: 4.2rem 0 4.1rem;
  }

  &__info {
    flex-shrink: 0;
  }

  &__bg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-img {
      opacity: .15;
      width: 100%;
      min-width: 192rem;
      min-height: 100%;
    }
  }

  @media (max-width: $md) {
    overflow: visible;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;

    &__inner {
      padding: 1rem 0 2rem;
    }
  }

  @media(max-width: $md) {
    margin-bottom: 3rem;

    &__inner {
      padding: .7rem 0 2.3rem;
    }
  }

}
