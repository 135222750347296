@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";


.write-feedback {
  width: 59rem;
  max-height: calc(100vh - 5rem);


  &__inner {
    padding: 5.8rem 7.3rem;
    border-radius: 4.5rem;
    text-align: center;
    background-color: var(--color-white);

  }

  &__title {
    color: var(--color-orange);
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 125%;
    text-transform: uppercase;

    margin-bottom: 1.2rem;
  }

  &__description {
    font-size: 1.6rem;
    font-weight: 300;

    margin-bottom: 4.8rem;
  }

  &__select {
    margin-bottom: 3.5rem;
  }

  &__input {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn {
    min-width: 22.2rem;
    margin-top: 3rem;
  }

  @media (max-width: $md) {
    width: 100%;

    &__inner {
      margin-top: .3rem;
      padding: 3.5rem 3rem 6rem;

      border-radius: 2.5rem 2.5rem 0 0;

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 50%;
        width: 13rem;
        height: .6rem;
        background-color: var(--color-orange);
        transform: translateX(-50%);
        border-radius: 4.7rem;
      }
    }

    &__description {
      font-size: 1.4rem;

      margin-bottom: 3rem;
    }

    &__form {
      display: flex;
      flex-direction: column;
    }

  }

}
