@import './src/assets/scss/breakpoints';
.blog-items {
  margin-bottom: 11rem;

  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 6rem;
  }

  @media (max-width: $lg) {
    &__body {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: $md) {
    margin-bottom: 5rem;

    &__body {
      grid-template-columns: 1fr;
      grid-row-gap: 5rem;
    }
    .container {
      padding: 0;
    }
  }
}
