@import './src/assets/scss/breakpoints';

.rehabilitation-center {

  &__info {
    margin-bottom: 10rem;

    &-title {
      font-style: italic;
      font-weight: 800;
      font-size: 3rem;
      text-transform: uppercase;
      color: var(--color-orange);
      margin-bottom: 2.5rem;
    }

    &-row {
      display: flex;
      align-items: flex-start;
    }

    &-text {
      flex-grow: 1;

      ul {
        list-style: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 1.5rem;
      }
    }

    &-picture {
      flex: 0 0 34.7rem;
      position: relative;


      margin-left: 5.4rem;

      &:before {
        content: '';
        display: block;
        padding-top: 178%;
      }
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2.5rem;
    }

  }

  &__trainer {
    margin-bottom: 10rem;
  }

  &__map {
    &-title {
      font-style: italic;
      font-weight: 800;
      font-size: 55px;
      text-transform: uppercase;

      margin-bottom: 4.5rem;
    }
  }

  &__slider {
    margin-bottom: 16.5rem;
  }

  @media (max-width: $md) {

    .map.club-top__map {
      display: none !important;
    }

    &__info {
      margin-bottom: 3.5rem;

      &-title {
        font-style: italic;
        font-weight: 500;
        font-size: 1.4rem;

        margin-bottom: 0;
      }
    }

    &__trainer {
      margin-bottom: 0;

      .trainer {
        &__left {
          height: 37.5rem;
        }

        &__description {
          font-weight: 600;
          font-style: italic;
          margin-bottom: 0 !important;
        }

        &-list {
          &__title {
            font-style: italic;
            font-size: 1.6rem;
            font-weight: 600;
            text-transform: none;
            color: inherit;

            margin-bottom: 1rem;
          }
        }

      }
    }

    &__advantages {
      margin-bottom: 3.5rem;
    }

    &__carousel {
      position: relative;
      margin-bottom: 3rem;

      .swiper-wrapper {

        display: flex;
      }

      &-item {
        opacity: 0 !important;
        pointer-events: none;
        flex-shrink: 0;
        width: 100vw;

        &.swiper-slide-active {
          pointer-events: auto;
          opacity: 1 !important;
        }
      }

      &-pagination {
        top: 34rem;
        bottom: auto;
      }

    }

    &__trainer {
      .trainer {
        &__title {
          display: none;
        }

        &__description {
          margin-bottom: .5rem;
        }

      }
    }

    &__map-title {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      color: var(--color-orange);

      margin-bottom: 2rem;

      border-top: 2px solid var(--color-black);
      padding-top: 4rem;
    }

    .club-top__item-title {
      margin-bottom: .3rem;
    }

    .club-top {
      margin-bottom: 5.5rem;
    }

  }

}
