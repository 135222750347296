@import "./src/assets/scss/breakpoints";

.button-arrow-right {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;

  svg {
    width: 0.8rem;
    height: 1.2rem;
    margin-left: 1.4rem;
    margin-top: -0.2rem;

    transition-property: transform;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transform: scale(0.9) translateX(0.3rem);
      }
    }
  }

  &._orange {
    svg {
      color: var(--color-orange);
    }
  }

  &._green {
    svg {
      color: var(--color-green);
    }
  }

  @media (max-width: $md) {
    &._mod {
      font-weight: 700;
      color: var(--color-orange);
      text-decoration: underline;

      svg {
        display: none;
      }
    }
  }

}
