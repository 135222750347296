@import './src/assets/scss/breakpoints';
@import './src/assets/scss/utils';

.button {
  position: relative;
  width: 100%;
  max-width: 22.2rem;

  min-height: 4.5rem;
  font-weight: 600;
  font-size: 1.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;

  box-shadow: 0 1.5rem 3.5rem rgba(255, 166, 33, 0.15);
  border-radius: 0.5rem;
  padding: .5rem 2rem;
  border: 2px solid;

  transition-property: all;
  transition-duration: var(--duration);
  transition-timing-function: var(--easing);

  &__svg {
    position: absolute;
    top: 50%;
    left: calc(100% + 1rem);
    transform: translateY(-50%);
    opacity: 0;
    width: 1.1rem;
    height: 1rem;

    transition-property: opacity;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

    &._contrast {
      display: none;
      position: static;
      transform: none;
    }
  }


  &__empty {
    width: 0;

    transition-property: width;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);
  }

  &__text {
    position: relative;
    padding-top: .14em;
  }

  &:hover &__svg {
    opacity: 1;
  }

  &:hover {
    @media (min-width: $md) {
      &._contrast {
        .button {

          &__svg {
            display: none;

            &._contrast {
              display: block;
            }
          }

          &__empty {
            width: 25%;
          }
        }
      }
    }
  }

  &._orange {
    background: var(--color-orange);
    border-color: var(--color-orange);
  }

  &._green {
    background: var(--color-green);
    box-shadow: 0 1.5rem 3.5rem rgba(93, 201, 55, 0.15);
    border-color: var(--color-green);

    &::before {
      box-shadow: 0 0.5rem 1.5rem rgba(93, 201, 55, 0.25);
    }
  }

  &._big {
    min-height: 5.5rem;
  }

  @media (max-width: $md) {
    min-height: 5.5rem;
  }

}
