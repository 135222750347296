.googlemap {
    position: relative;
    padding-bottom: 75%; /* 4:3 ratio*/
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
