@import "src/assets/scss/utils";

.mobile-list {

  &._show &__top {
    border-bottom-color: var(--color-orange);

    &-svg {
      transform: rotate(-180deg);
    }
  }

  &__top {
    width: 100%;
    padding: 2rem 0 2rem;
    border-bottom: 2px solid var(--color-black);

    transition-property: border-bottom-color;
    transition-duration: var(--duration-long);
    transition-timing-function: var(--easing);

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      font-size: 1.8rem;
      text-transform: uppercase;
      color: var(--color-orange);
      text-align: left;

      svg {
        width: 1.1rem;
        height: 0.8rem;
        margin-right: 1rem;
        flex: 0 0 1.1rem;
        margin-left: 2rem;

        transition-property: transform;
        transition-duration: var(--duration);
        transition-timing-function: var(--easing);
      }
    }

    &-recruiting {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 160%;
      text-align: center;
      text-transform: uppercase;
      color: var(--color-white);
      background: var(--color-green);
      border-radius: 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 3.4rem;
      margin-top: 0.6rem;
    }
  }

  &__wrapper {
    height: 0;
    overflow: hidden;

    transition-property: height;
    transition-duration: var(--duration);
    transition-timing-function: ease;
  }

  &__list {
    border-bottom: 2px solid var(--color-black);
  }

  &__item {
    padding: 1.8rem 0 1.8rem;
    border-bottom: 2px solid var(--color-white-1);

    &:last-child {
      //border-color: var(--color-black);
      border-bottom: none;
    }

    &-text {
      display: block;
      font-style: italic;
      line-height: 145%;
      text-transform: uppercase;
      margin-bottom: .5rem;
    }

    &-price {
      display: inline-flex;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 145%;
      text-transform: uppercase;

      &._time {
        &::after {
          content: '/';
          margin: 0 0.5rem;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }

    &-sep {
      display: inline-flex;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 145%;
      text-transform: uppercase;
      margin: 0 0.5rem;

      &:last-child {
        display: none;
      }
    }
  }

  &__baner {
    position: relative;
    margin-bottom: 1.8rem;

    &:before {
      content: '';
      position: absolute;
      top: -.2rem;
      left: 0;
      width: 100%;
      border-top: 2px solid white;
    }

    & > * {
      margin-top: 0;
      margin-bottom: 1.8rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}
