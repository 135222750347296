.pagination-fraction {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 1rem;

  transform: translateX(-50%);

  font-weight: 600;
  color: var(--color-white-1);
}
