@import './src/assets/scss/breakpoints';
.sport-bar {
  margin: 11rem 0 8rem;

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    flex-basis: 48.5rem;
    margin-right: 2rem;
  }

  &__img {
    position: relative;
    flex-basis: 57rem;
    border-radius: 3.5rem;
    overflow: hidden;
    min-height: 22rem;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 3rem;
    line-height: 125%;
    text-transform: uppercase;
    color: var(--color-orange);
    margin-bottom: 2rem;
  }

  &__text {
    font-style: italic;
    font-size: 1.8rem;
    line-height: 183.5%;
    text-transform: uppercase;
  }
  @media (max-width: $md) {
    margin: 3.6rem 0 8rem;

    &__body {
      display: block;
    }

    &__content {
      flex: 1 1 auto;
      margin-right: 0;
    }

    &__title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 145%;
      color: var(--color-black);
      margin-bottom: 1rem;
    }

    &__text {
      font-style: normal;
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 152.02%;
      margin-bottom: 3rem;
    }
  }

  @media (max-width: $sm) {
    &__img {
      min-height: 15rem;
      border-radius: 1.5rem;
    }
  }
}
