@import './src/assets/scss/breakpoints';

.list-title {
  display: flex;

  &__item {
    display: flex;
    margin-right: 3rem;
    font-style: italic;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.2;
    align-items: center;
    text-transform: uppercase;

    color: var(--color-orange);

    svg {
      width: 2.5rem;
      height: 2.3rem;
      margin-right: 1.4rem;
    }
  }

  @media (max-width: $md) {
    flex-wrap: wrap;

    &__item {
      //color: var(--color-blue) !important;
      margin-right: 2.5rem;
      margin-bottom: 1.1rem;
      font-weight: 500;

      &:nth-child(1) {
        order: 3;
      }

      &:nth-child(2) {
        order: 2;
      }

      &:nth-child(3) {
        order: 1;
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1rem;
      }
    }
  }
}
