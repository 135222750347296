@import "breakpoints";

.paginate {

  display: flex;
  justify-content: center;

  &__previous,
  &__next {
    display: none;
  }

  &__item,
  &__break {

    margin-right: 1.2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item._active &__link {
    font-weight: 700;
    color: white;
    background-color: var(--color-orange);
    border-color: var(--color-orange);
  }

  &__link {
    min-width: 5.4rem;
    height: 5.4rem;
    font-weight: 500;
    font-size: 1.8rem;
    color: var(--color-black);
    background: fade-out(#EFEFEF, .45);
    border: .3rem solid fade-out(#EFEFEF, .45);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    padding: 1rem;

    transition-property: border-color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

  }

  &__break {
    align-self: center;
  }

  @media(max-width: $md) {
    &__link {
      font-size: 1.4rem;
      min-width: 4rem;
      height: 4rem;
    }
  }

}
