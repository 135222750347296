@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.base-select {

  &__html-select {
    display: none;
  }

  &__inner {
    position: relative;
  }

  &__trigger {
    @include resetBtn;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: .2rem solid var(--color-orange);
    padding: 0 2.3rem 1rem;


    &-inner {
      @include clipText();
      flex: 1 1 0;
      width: 1%;
      margin-right: 2rem;
    }

    &-svg {
      color: var(--color-orange);
      width: 1.2rem;
      height: .8rem;
    }
  }

  &__dropdown {
    @include customScroll;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    right: 0;

    overflow-x: hidden;
    overflow-y: auto;
    max-height: 31.5rem;

    &-list {
      @include resetList;
      background: var(--color-white);
      box-shadow: 0 6.5rem 10rem rgba(7, 7, 19, 0.1);
      padding: 2.1rem 2.8rem;
      text-align: left;
    }

    &-item {
      cursor: pointer;
      margin-bottom: 2.1rem;

      &:hover {
        .base-select__dropdown-title {
          svg {
            opacity: 1;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-title {
      position: relative;
      color: var(--color-orange);
      font-style: italic;
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase;

      display: inline-block;

      svg {
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 3rem);
        width: 2.3rem;
        height: 2.1rem;

        transition-property: opacity;
        transition-duration: var(--duration-long);
        transition-timing-function: var(--easing);
      }
    }

    &-sub {
      &-list {
        @include resetList;
      }

      &-item {
        margin-bottom: .8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-btn {
        @include resetBtn;
        font-weight: 300;
      }

    }

  }


  &__errors {
    @include resetList;

    &-item {
      color: var(--color-red);
      margin-top: .5rem;
      font-size: 1.2rem;
      text-align: right;
    }
  }

  @media (max-width: $md) {
    &__trigger {
      font-size: 1.4rem;

      &-inner {
        max-width: 23rem;
      }

    }

    &__dropdown {
      &-sub {
        &-btn {
          font-size: 1.3rem;
        }
      }
    }

  }

}
