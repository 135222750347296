@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.photo-gallery {

  &__list {
    @include resetList;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3.8rem 3rem;

    margin-bottom: 13.5rem;
  }

  &__item {
    cursor: pointer;

    &:hover &-img {
      transform: scale(1.2);
    }

    &-button {
      @include resetList;
      display: block;
      width: 100%;
    }

    &-picture {
      border-radius: 2.5rem;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 63%;
      }
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;

      border-radius: inherit;
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition-property: transform;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }
  }

  &__promo {
    color: var(--color-orange);

    svg {
      transform: scaleX(-1);
    }
  }

  @media (max-width: $md) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: $sm) {

    .promo {
      margin-bottom: 0;
    }

    &__list {
      display: block;

      margin-left: -3rem;
      margin-right: -3rem;
      margin-bottom: 0;
    }

    &__item {
      margin-bottom: .5rem;

      &:last-child {

        margin-bottom: 0;
      }

      &-picture {
        border-radius: 0;
      }
    }

  }

}
