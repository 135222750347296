.blog-tags {
  color: white;
  background: var(--color-orange);
  min-height: 3.4rem;
  padding: .5rem 2rem;
  border-radius: 6.9rem;
  display: inline-flex;
  align-items: center;

  flex-wrap: wrap;

  font-weight: 600;
  font-size: 1.2rem;
  line-height: 135%;

  &__item {
    text-transform: lowercase;
  }

}
