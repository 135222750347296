.club-price {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 5.8rem;
  }

  &__schedule {
    margin-top: 10rem;
    background-color: var(--color-green);
    padding: 1.1rem 0;
    width: 100%;

    transition-property: background-color;
    transition-duration: var(--duration-long);
    transition-timing-function: var(--easing);

    &:hover {
      background-color: var(--color-green-1);
    }

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 1.2rem;
        height: 1.8rem;
      }
    }

    &-link {
      display: inline-flex;
      margin: 0 4.5rem;
      font-style: italic;
      font-weight: 800;
      font-size: 1.8rem;
      line-height: 125%;
      text-align: center;
      text-transform: uppercase;
    }

  }
}
