.price-list {
  margin-top: 5.8rem;

  &__title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 125%;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;

    span {
      background: var(--color-green);
      border-radius: 1rem;
      padding: 0.9rem 2.1rem 0.8rem;
      color: var(--color-white);
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 160%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 145%;
    text-transform: uppercase;
    padding: 2.5rem 0 1.8rem;
    border-bottom: 2px solid var(--color-white-1);

    &-text {
      font-style: italic;
      max-width: 60rem;
    }

    &-price {
      font-size: 2rem;
      font-weight: 700;

      &._small {
        text-align: right;
        font-weight: 500;
        text-transform: none;
      }

      span {
        display: block;
      }
    }
  }

  &__notice {
    font-style: italic;
    line-height: 152.02%;
    color: black;
    margin-top: 0.9rem;
  }
}
