@import "./src/assets/scss/breakpoints";

.base-new {
  position: absolute;
  z-index: 3;
  top: 2.4rem;
  right: 2rem;

  padding: .8rem 1.8rem;

  background-color: var(--color-green);
  box-shadow: 0 0.5rem 1.5rem rgba(64, 255, 33, 0.55);
  border-radius: 1rem;

  font-weight: 700;
  font-size: 1.2rem;
  line-height: 160%;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);

  &._orange {
    background: var(--color-orange);
    box-shadow: 0 .5rem 1.5rem rgba(255, 166, 33, 0.35);
  }

  &._red {
    background: var(--color-red-2);
    box-shadow: 0 .5rem 1.5rem rgba(255, 63, 44, 0.35);
  }

  @media(max-width: $md) {
    top: 2rem;
    right: 3rem;

    padding: .7rem 1.8rem;
  }

}
