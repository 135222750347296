@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";


.contacts {
  &__list {
    @include resetList;

    margin-bottom: 22.8rem;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 2rem;

    padding-bottom: 4rem;
    margin-bottom: 4rem;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: .2rem;

      background: linear-gradient(90deg, #FFA621 0%, #FC714F 100%);
    }


    &:hover &-title-svg {
      transform: translateX(0) scale(1);
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;

      &:before {
        content: none;
      }
    }

    &-map {
      flex-shrink: 0;
      flex-basis: 57rem;
      height: 22rem;
    }

    &-content {

    }

    &-title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 145%;

      color: var(--color-orange);

      display: flex;
      align-items: center;
      text-transform: uppercase;

      margin-bottom: 2rem;

      &-svg {
        transform: translateX(-100%) scale(0);
        //color: var(--color-green);
        width: 2.9rem;
        height: 2.5rem;

        transition-property: transform;
        transition-duration: var(--duration);
        transition-timing-function: var(--easing);

        margin-left: 1.5rem;
      }
    }

    &-items {
      @include resetList;
    }

    &-item {
      font-size: 1.6rem;
      line-height: 125%;

      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-label {
      font-weight: 500;
      margin-right: .5rem;
    }

    &-value {
      font-weight: 300;
    }

  }

  &__btn {
    color: var(--color-orange);
  }

  @media(max-width: $md) {

    &__list {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &__item {
      padding-bottom: 3.3rem;
    }

    &__item {
      display: block;

      padding-bottom: 0;
      margin: 0 -2.5rem 3rem;

      &-title {
        font-size: 1.6rem;

        margin-bottom: 1.2rem;

        &-svg {
          width: 2rem;
          height: 1.8rem;
        }
      }

      &-item {
        font-size: 1.4rem;
      }

      &-content {
        padding-left: 2.5rem;
        padding-right: 2.5rem;

        margin-bottom: 3rem;
      }

    }

    &__btn {
      &.btn {
        align-items: center;
        font-size: 1.4rem;
        font-style: normal;
      }


      &-svg {
        width: 1.9rem;
        height: 1.9rem;
      }
    }

  }

}
