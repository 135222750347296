@import './src/assets/scss/breakpoints';

.clubs-page {
  &__wrapper {
    margin-bottom: 13.5rem;
  }

  &__ticker {
    margin-bottom: -0.2rem;
  }

  &__map {
    margin: 0;

    .map.map-home__map {
      height: 55rem;
    }
  }

  @media (max-width: $md) {
    &__wrapper {
      position: relative;
      z-index: 1;
      margin-bottom: -0.2rem;
    }

    .promo {
      margin-bottom: 0;
    }

    &__map {
      .container {
        padding: 0;
      }

      .map.map-home__map {
        border-radius: 0;
        margin: 0;
        height: 58rem;
      }
    }
  }
}
