.mobile-top {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 125%;
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: 0.5rem;

  span {
    display: block;
    font-style: italic;
    font-weight: 800;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
}
