@import '../../../assets/scss/breakpoints';
@import '../../../assets/scss/utils';
@import '../../../assets/scss/mixins';

.reviews-home {
  margin: 9rem 0 12.7rem;

  &__quotes {
    width: 3.8rem;
    height: 2.8rem;
    margin: 0 auto 3.5rem;
  }

  &__slider {
    padding: 0 3rem;
  }

  &__slide {
    flex: 0 0 calc(100% - 6rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 3rem;

    &-top {
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 125%;
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;

      span {
        font-weight: 500;
      }
    }

    &-text {
      @include clipTextHeight(1.5, 3);
      font-style: italic;
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 145%;
      text-align: center;
      max-width: 113rem;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6.4rem;

    &-modal {
      @include resetBtn;
      font-weight: 700;
      color: var(--color-orange);
      font-size: 1.4rem;
      text-decoration-line: underline;
      text-transform: uppercase;

      margin: 0 3.3rem;
    }
  }


  @media (max-width: $md) {
    margin: 6rem 0 8rem;

    &__quotes {
      margin-bottom: 2.1rem;
    }

    &__slider {
      padding: 0;
    }

    &__buttons {
      & > * {
        margin-right: 2.8rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__mobile {
      @include resetBtn;
      font-weight: 500;
      font-size: 1.4rem;
      text-decoration-line: underline;
      text-transform: uppercase;
      display: flex;
      margin-top: 4.5rem;
      margin-right: auto;
      margin-left: auto;
      justify-content: center;
      text-align: center;
    }

    &__slide {
      &-top {
        flex-direction: column;
        line-height: 145%;
        font-size: 1.6rem;

        margin-bottom: 2.1rem;

        span {
          font-size: 2rem;
        }
      }

      &-text {
        @include clipTextHeight(1.5, 10);
        font-size: 1.5rem;
      }
    }

    &__buttons {
      margin-top: 4.4rem;
    }

    &__mobile {
      &-btn {
        margin: 3.5rem auto 0;
      }
    }

  }
}
