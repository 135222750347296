@import '../../assets/scss/breakpoints';
.blog-inner {
  display: block;

  &__top {
    position: relative;
    padding-top: 57%;
    border-radius: 3.5rem;
    overflow: hidden;
    margin-bottom: 1.7rem;
    display: block;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition-property: transform;
      transition-duration: var(--duration-long);
      transition-timing-function: var(--easing);
    }

    @media (hover: hover) {
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.8rem;
    row-gap: 1.1rem;
  }

  &__date {
    margin-right: 1.1rem;
  }

  &__title {
    display: block;
    font-size: 1.4rem;
    line-height: 110%;

    transition-property: color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

    &:hover {
      color: var(--color-orange);
    }
  }

  @media (max-width: $md) {
    &__top {
      border-radius: 0;
      margin-bottom: 0;
    }

    &__info {
      position: relative;

      margin-bottom: 4.2rem;
    }

    &__date {
      position: absolute;
      top: -2.2rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &__title {
      font-size: 1.6rem;
      text-align: center;
      padding: 0 3rem;
    }
  }
}
