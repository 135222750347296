@import './src/assets/scss/mixins';
@import "src/assets/scss/utils";

.reviews {

  .paginate {
    margin-bottom: 9.8rem;
  }

  &__content {
  }

  &__list {
    @include resetList;
  }

  &__list,
  &__item {
    position: relative;
    padding-bottom: 3.8rem;
    //border-bottom: 0.2rem solid var(--color-white-1);
    margin-bottom: 3.5rem;

    &:before {
      content: '';
      position: absolute;
      top: calc(100% + .2rem);
      left: 0;
      width: 100%;
      height: .2rem;
      background: linear-gradient(90deg, #FFA621 0%, #FC714F 100%);
    }

  }

  &__item {

    &:last-child {
      padding-bottom: 0;
      border: none;
      margin-bottom: 0;

      &:before {
        content: none;
      }
    }

    &-top {
      display: flex;
      align-items: center;

      margin-bottom: 3.1rem;
    }

    &-main {

    }

    &-quotes {
      width: 2.7rem;
      height: 2rem;

      margin-right: 2.3rem;
    }

    &-info {
      font-size: 1.8rem;
      line-height: 125%;
      font-weight: 300;

      &-name {
        font-weight: 500;

        margin-right: 1rem;
      }
    }

    &-date {
      display: flex;
      align-items: center;

      color: var(--color-orange);
      font-size: 1.6rem;
      font-style: italic;
      font-weight: 600;

      margin-left: auto;

      &-svg {
        width: 1.5rem;
        height: 1.5rem;

        margin-right: 1.1rem;
      }
    }

    &-main {
      font-weight: 300;
      line-height: 152.02%;
    }

    &-col {
      margin-top: .9rem;
    }

    &-answer {
      display: flex;

      margin-top: 2.1rem;

      &-svg {
        width: 3.6rem;
        height: 3rem;

        margin-right: 2rem;
      }
    }

    &-name {
      font-weight: 500;
      font-size: 1.8rem;

      margin-bottom: .6rem;
    }

    &-text {
      font-weight: 300;
    }

  }

  @media(max-width: $md) {

    .paginate {
      margin-bottom: 7rem;
    }

    &__list,
    &__item {
      padding-bottom: 3rem;
      margin-bottom: 3rem;
    }

    &__item {
      &-top {
        margin-bottom: 1.9rem;
      }

      &-info {
        font-size: 1.8rem;
      }

      &-street,
      &-main,
      &-date,
      &-text,
      &-name {
        font-size: 1.4rem;
      }

      &-quotes {
        width: 2rem;
        height: 1.5rem;

        margin-right: 1.5rem;
      }

      &-mobile {
        &-street {
          font-weight: 600;
          line-height: 125%;

          margin-bottom: .6rem;
        }
      }

      &-main {
        margin-bottom: 1.6rem;
      }

      &-answer {
        margin-top: 1.7rem;

        &-svg {
          width: 2.6rem;
          height: 2.2rem;

          margin-left: .6rem;
          margin-right: 1.1rem;
        }
      }

      &-name {
        line-height: 125%;

        margin-bottom: .4rem;
      }

    }

    &__btn.btn {
      font-size: 1.4rem;
    }

  }


}
