.btn-slider {
  width: 7.1rem;
  height: 7.1rem;
  background: var(--color-white);
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition-property: background, color;
  transition-duration: var(--duration);
  transition-timing-function: var(--easing);

  &.swiper-button-disabled {
    cursor: not-allowed;
  }

  &._small {
    width: 5.4rem;
    height: 5.4rem;
  }

  &._gray {
    background-color: var(--color-white-1);
  }

  svg {
    height: 1.2rem;
    width: 1rem;
  }

  &._prev {
    svg {
      transform: rotate(-180deg);
    }
  }

  @media (hover: hover) {
    &._hover:not(&.swiper-button-disabled) {
      &:hover {
        color: var(--color-white);
        background: var(--color-orange);
      }
    }
  }

}
