@import './src/assets/scss/breakpoints';
@import './src/assets/scss/mixins';

.trainers-item {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  &__top {
    display: block;
    position: relative;
    border-radius: 2.5rem;
    overflow: hidden;
    margin-bottom: 2.1rem;

    &:after {
      content: '';
      display: block;
      padding-top: 106%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;

      transition-property: transform;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
                      180deg,
                      rgba(7, 7, 19, 0) 49.48%,
                      rgba(7, 7, 19, 0.9375) 100%
      );
    }

    @media (hover: hover) {
      &:hover {
        img {
          transform: scale(1.05);
        }

        .trainers-item__svg,
        .trainers-item__title {
          transform: translateY(-1rem);
        }
      }
    }
  }

  &__bottom {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &-link {
      margin-top: auto;
    }
  }

  &__content {
    position: absolute;
    z-index: 2;
    bottom: 2.6rem;
    right: 2.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__svg {
    width: 9.7rem;
    height: 1.2rem;
    margin-bottom: 0.5rem;
    transition-property: transform;
    transition-duration: var(--duration);
    transition-timing-function: ease;
  }

  &__title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 145%;
    text-transform: uppercase;
    color: var(--color-white);
    transition-property: transform;
    transition-duration: var(--duration);
    transition-timing-function: ease;

    text-align: right;
  }

  &__text {
    @include clipTextHeight(1.5, 3);
    font-weight: 300;
    line-height: 152.02%;
    margin-bottom: 2rem;
  }

  @media (max-width: $md) {



    &__top {
      border-radius: 0;
      margin-bottom: 0;

      &:after {
        padding-top: 100%;
      }

      img {
        object-fit: contain;
      }
    }
  }
}
