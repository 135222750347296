.button-arrow {
  font-style: italic;
  font-weight: 700;
  font-size: 3rem;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &._orange {
    color: var(--color-orange);
  }

  &._green {
    color: var(--color-green);
  }

  svg {
    width: 4.2rem;
    height: 3.5rem;
    margin-left: 3rem;
  }
}
