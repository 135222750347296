@import '../../../assets/scss/breakpoints';
@import '../../../assets/scss/utils';

.corporate {
  margin: 7rem 0 9rem;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 5.4rem;
  }

  &__title {
    font-weight: 600;
    font-size: 3rem;
    line-height: 125%;
    text-transform: uppercase;
  }

  @media (max-width: $md) {
    margin: 6rem 0 6rem;


    &__head {
      justify-content: center;

      margin-bottom: 3.5rem;
    }

    &__title {
      font-size: 1.8rem;
    }
  }

}
