.club-plans {
  &__title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 155%;
    text-align: center;
    text-transform: uppercase;
    max-width: 95rem;
    margin: 0 auto 3.7rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    min-width: 34.4rem !important;
    margin-right: 3rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
