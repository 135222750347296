@import './src/assets/scss/breakpoints';
@import './src/assets/scss/utils';
@import './src/assets/scss/mixins';

.club-trainers {
  margin: 8rem 0 10rem;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6.7rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__btn {
    width: 5.4rem;
    height: 5.4rem;
    background-color: transparentize(#efefef, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 3rem;

    svg {
      width: 2.1rem;
      height: 0.9rem;
    }

    &:last-child {
      margin-right: 0;
    }

    &-prev {
      transform: rotate(-180deg);
    }
  }

  &__slider {
    position: relative;
    width: 100%;
  }

  &__slide {
    width: calc((100% - (3rem * 3)) / 4);
    margin-right: 3rem;

    flex-shrink: 0;

    display: flex;
    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

  }

  &__pug {
    width: 100%;
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-top: 106%;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: $md) {
    margin-top: 4.5rem;
    margin-bottom: 5rem;

    .container {
      padding: 0;
    }

    &__wrapper {
      margin: 3rem 3rem 0;
      //border-bottom: 2px solid var(--color-black);
      //padding-bottom: 3.7rem;
    }

    &__title {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-transform: uppercase;
      color: var(--color-orange);
      margin-bottom: 0.1rem;
    }

    &__slider {
      &-sync {
        position: relative;

        &-slide {
          flex: 0 0 100%;
          background: var(--color-white);
          pointer-events: none;

          &.swiper-slide-active {
            pointer-events: auto;
          }
        }

        &-name {
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 145%;
          text-transform: uppercase;
          margin-bottom: 0.9rem;
        }

        &-text {
          @include clipTextHeight(1.5, 4);
          font-weight: 300;
          font-size: 1.4rem;
          line-height: 152.02%;
          margin-bottom: 3rem;
        }
      }
    }


    &__slide {
      width: 100%;
      margin-right: 0;
    }
  }
}
