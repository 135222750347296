@import './src/assets/scss/breakpoints';

.coach-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.4rem;

  &__title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 155%;
    text-align: center;
    margin-bottom: 3rem;

    span {
      display: block;
      text-transform: uppercase;
    }
  }

  @media (max-width: $md) {

    &__title {
      font-size: 1.6rem;
      text-align: center;

      margin-bottom: 2rem;

      p {
        display: inline;
      }
    }

    &__btn.button {
      max-width: none;
    }

  }
}
