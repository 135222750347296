@import "breakpoints";
@import "mixins";

.d-none {
  display: none !important;
}

.d-block {
  display: block !important
}

.d-inline-block {
  display: inline-block !important
}

.d-inline {
  display: inline !important
}

.d-flex {
  display: flex !important
}

.d-inline-flex {
  display: inline-flex !important
}

.d-table-cell {
  display: table-cell !important
}

.d-grid {
  display: table-cell !important
}

@each $key, $value in $breakpoints {
  @include media-down($key) {
    .d-#{$key}-none {
      display: none !important
    }

    .d-#{$key}-block {
      display: block !important
    }

    .d-#{$key}-inline-block {
      display: inline-block !important
    }

    .d-#{$key}-inline {
      display: inline !important
    }

    .d-#{$key}-flex {
      display: flex !important
    }

    .d-#{$key}-inline-flex {
      display: inline-flex !important
    }

    .d-#{$key}-table-cell {
      display: table-cell !important
    }

    .d-#{$key}-grid {
      display: grid !important
    }
  }
}
