@import "./src/assets/scss/breakpoints";
@import "./src/assets/scss/utils";


.gallery {
  width: 102.4rem;
  max-height: calc(100vh - 6rem);

  position: relative;

  &__carousel {
    overflow: hidden;
    border-radius: 5.5rem;
    box-shadow: var(--box-shadow-4);

    & .swiper-wrapper {
      display: flex;
    }

    &-item {
      flex-shrink: 0;
      width: 100%;
    }

    &-picture {
      display: flex;
      position: relative;


      &:before {
        content: '';
        display: block;
        padding-top: 67%;
      }
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &__navigation {
    position: absolute;
    top: 50%;
    left: -11.8rem;
    right: -11.8rem;

    display: flex;
    justify-content: space-between;

    &-item {
      width: 7.3rem;
      height: 7.3rem;
      background-color: var(--color-orange);
      border-radius: 50%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &._prev {
        transform: scale(-1);
      }
    }

    &-svg {
      width: 40%;
      max-height: 50%;
    }
  }

  @media (max-width: $xl) {
    width: 70rem;
  }

  @media (max-width: $md) {
    width: 100%;
  }

}
