@import './src/assets/scss/breakpoints';
.clubs-items {
  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
    grid-column-gap: 3rem;
    grid-row-gap: 5rem;
  }

  @media (max-width: $md) {
    &__body {
      grid-template-columns: 1fr;
      grid-row-gap: 0;
    }

    .container {
      padding: 0;
    }
  }
}
