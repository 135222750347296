.border {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5rem;
    background: linear-gradient(90deg, #FFA621 0%, #FC714F 100%);
  }

  @media (max-width: $md) {
    &:before {
      height: 0.3rem;
    }
  }
}
