@import "breakpoints";

.modal {
  @media(max-width: $md) {


    &__inner {

    }
  }
}

.ReactModal {
  &__Content {
    color: rgba(7, 7, 19, 0.25);
    transform: scale(.7);

    align-items: center;
    justify-content: center;

    &--after-open {
      opacity: 1;
      transform: scale(1);

      transition-property: transform, opacity;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }

    &--before-close {
      transform: scale(.4);
      opacity: 0;
    }

  }


  @media(max-width: $md) {
    &__Content {
      width: 100%;
      transform: translateY(100%);

      &--after-open {
        transform: translateY(0);
      }

      &--before-close {
        transform: translateY(100%);
      }

    }
  }
}