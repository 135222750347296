.trainer-page {
  &__title {
    display: flex;
    align-items: center;
    font-style: italic;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    text-transform: uppercase;
    color: var(--color-orange);

    svg {
      width: 2.8rem;
      height: 2.6rem;
      margin-right: 1.1rem;
    }
  }

  &__ticker {
    margin-bottom: -0.2rem;
  }
}
