@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";


.base-input {
  display: block;

  &._focus &__input {
    border-color: var(--color-black);
  }

  &__inner {
    display: flex;
    width: 100%;
  }

  &__input {
    flex-grow: 1;
    height: 100%;
    background: fade-out(#EFEFEF, .5);
    font-weight: 300;
    font-size: 1.6rem;
    padding: 1.5rem 2.3rem;
    border: .1rem solid fade-out(#EFEFEF, .5);
    border-radius: 1rem;

    transition-property: border-color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

    &._textarea {
      min-height: 14rem;
    }

    &::placeholder {
      color: var(--color-black);
    }
  }

  &__errors {
    @include resetList;
    margin-top: .5rem;

    &-item {
      color: var(--color-red);
      font-size: 1.2rem;
      text-align: right;
    }
  }

  @media (max-width: $md) {
    &__input {
      font-size: 1.4rem;

      &._textarea {
        min-height: 12.5rem;
      }
    }
  }

}