$colorOrange: #FF9C16;

:root {
  --f-base-family: 'Inter', Helvetica, Arial, sans-serif;
  --f-root-size: 10px;
  --f-base-size: 1.4rem;
  --f-base-height: 1.5;

  --color-black: #070713;
  --color-white: #fff;
  --color-white-1: #efefef;
  --color-grey: #56556d;
  --color-grey-2: #767676;
  --color-orange: #{$colorOrange};
  --color-orange-2: #F9BC25;
  --color-blue: #263f71;
  --color-purple: #383b8f;
  --color-purple-2: #7275d7;
  --color-purple-3: #af8bc2;
  --color-purple-4: #3f4294;
  --color-green: #5dc937;
  --color-green-1: #7DE159;
  --color-pink: #d37ebc;
  --color-light-red: #ff8592;
  --color-red: #fe003b;
  --color-red-2: #FF3F2C;

  --box-shadow: 0 0.3rem 0.5rem rgba(255, 166, 33, 0.25);
  --box-shadow-2: 0 6.5rem 3.5rem var(--color-black);
  --box-shadow-3: 0 1.1rem 2.5rem rgba(194, 194, 194, 0.55);
  --box-shadow-4: 0 6.5rem 10rem rgba(0, 0, 0, 0.55);

  --duration: 0.3s;
  --duration-long: 0.6s;
  --easing: cubic-bezier(0.25, 0.8, 0.5, 1);
}
