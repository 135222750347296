@import '../../../assets/scss/breakpoints';
@import '../../../assets/scss/utils';

.home-clubs {
  margin: 6rem 0;
  overflow: hidden;

  &__body {
    display: flex;
    align-items: center;
  }

  &__info {
    position: relative;
    z-index: 3;
    flex: 0 0 24rem;
    margin-right: 5rem;
  }

  &__title {
    font-weight: 600;
    font-size: 3rem;
    line-height: 125%;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-weight: 300;
    line-height: 152.02%;
    margin-bottom: 3rem;
  }

  &__slider {
    width: 1%;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }

  &__slide {
    flex-shrink: 0;
    width: calc((100% - (3rem * 2)) / 3);
    margin-right: 3rem;
    border-radius: 2.5rem;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    &-link {
      display: block;
      position: relative;

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-image: linear-gradient(
                        180deg,
                        rgba(7, 7, 19, 0) 43.48%,
                        rgba(7, 7, 19, 0.9375) 100%
        );

        transition-property: background;
        transition-duration: var(--duration-long);
        transition-timing-function: var(--easing);
      }

      @media (hover: hover) {
        &:hover {
          &::after {
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
          }

          .home-clubs {
            &__slide {
              &-img {
                transform: scale(1.1);
              }

              &-arrow {
                opacity: 1;
              }

            }
          }
        }
      }
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      //filter: blur(.2rem);

      transition-property: transform;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }

    &-new,
    &-arrow,
    &-content {
      z-index: 3;
    }

    &-content {
      position: absolute;
      bottom: 3rem;
      right: 3rem;
      left: 3rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-logo {
      width: 9.7rem;
      height: 1.2rem;
      margin-bottom: 0.5rem;
      transition-property: transform;
      transition-duration: var(--duration);
      transition-timing-function: ease;
    }

    &-title {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 145%;
      text-align: right;
      text-transform: uppercase;
      color: var(--color-white);
    }

    &-arrow {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 4.5rem;
      height: 4rem;

      color: var(--color-orange);

      transition-property: opacity;
      transition-duration: var(--duration-long);
      transition-timing-function: ease;
    }

  }

  &__btn {
    position: absolute;
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);

    &._prev {
      left: -3.2rem;
    }

    &._next {
      right: -3.2rem;
    }

  }

  @media (max-width: $md) {
    margin: 5rem 0 6rem;

    &__body {
      display: block;
    }

    .container {
      padding: 0;
    }

    &__info {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 3.5rem;
      margin-right: 0;
      padding: 0 3rem;

      &::before {
        display: none;
      }
    }

    &__title {
      font-size: 1.8rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    &__subtitle {
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    &__slider {
      width: 100%;
      overflow: hidden;
      padding: 0 2.2rem;
    }

    &__slide {
      width: 100%;

      &-link {
        &::after {
          content: none;
        }

        &:before {
          padding-top: 91%;
        }

      }

      &-new {
        top: auto !important;
        bottom: 2rem;
        right: 2rem !important;
      }

      &-content {
        top: 0;
        left: 0;
        width: 100%;
        bottom: auto;

        align-items: flex-start;

        padding: 2.6rem 3rem;
        background: fade-out(black, .2) ;
      }

      &-title {
        text-align: left;
      }

      &-logo {
        width: 10.1rem;
        height: 1.3rem;
      }
    }

    &__btn {
      top: calc(50% + 3rem);

      &._prev {
        left: -1rem;
      }

      &._next {
        right: -1rem;
      }
    }
  }

}
