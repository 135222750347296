@import '../../assets/scss/breakpoints';

.home-page {

  &__ticker {
    margin: 9rem 0 9.5rem;
  }

  @media (max-width: $md) {
    &__ticker {
      margin: 6rem 0;
    }
  }

}
