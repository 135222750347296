@import './src/assets/scss/mixins';
@import './src/assets/scss/breakpoints';

.clubs-item {
  display: flex;
  flex-direction: column;

  @media (hover: hover) {
    &:hover &__arrow {
      opacity: 1;
    }
  }

  @media (min-width: $md) {
    &:hover &__img,
    &._darkening &__img {
      &:before {
        content: '';
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45));
      }
    }

    &._darkening._bold &__img {
      &:before {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45));
      }
    }
  }


  &__logo {
    width: 10.9rem;
    height: 2.2rem;
  }

  &__badge.base-new {
    z-index: 5;
  }


  &__arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: 5.4rem;
    height: 4.3rem;
    color: var(--color-orange);
    opacity: 0;

    transition-property: opacity;
    transition-duration: var(--duration-long);
    transition-timing-function: var(--easing);
  }

  &__title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 145%;
    text-transform: uppercase;
    margin-bottom: .8rem;
  }

  &__address {
    font-weight: 300;
    line-height: 125%;
    margin-bottom: 2.5rem;
  }

  &__img {
    margin-top: auto;
    position: relative;
    padding-top: 60%;
    border-radius: 2.5rem;
    overflow: hidden;

    //&:after {
    //  content: '';
    //  position: absolute;
    //  z-index: 4;
    //  top: 0;
    //  left: 0;
    //  width: 100%;
    //  height: 100%;
    //  backdrop-filter: blur(.2rem);
    //}

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: $md) {
    position: relative;
    min-height: 18rem;
    padding: 2rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--color-white);

    &__address {
      margin-bottom: 0;
    }

    &__img {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-top: 0;
      border-radius: 0;

      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-black);
        opacity: 0.9;
      }

      .base-new {
        z-index: 2;
      }
    }
  }
}
