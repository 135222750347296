@import '../../assets/scss/breakpoints';

.footer {
  padding-bottom: 5.5rem;

  &__body {
    border-top: 2px solid var(--color-black);
    padding-top: 5.5rem;
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;

    font-size: 1.6rem;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-basis: 81rem;
    gap: 8rem;

    //.footer__col {
    //  flex: 1 1 25%;
    //}
  }

  &__col {
    max-width: 26rem;

    &._sb {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 21.7rem;
    }
  }

  &__mail {
    font-weight: 500;
    line-height: 155%;
    letter-spacing: 0.03em;

    transition-property: color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

    @media (hover: hover) {
      &:hover {
        color: var(--color-orange);
      }
    }
  }

  &__copyright {
    font-size: 1.2rem;
    line-height: 155%;
    letter-spacing: 0.03em;
  }

  &__nav {
    flex-basis: 27.4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 11rem;
  }

  &__link {
    display: flex;
    align-items: center;
    line-height: 160%;
    margin-bottom: 2.4rem;
    white-space: nowrap;

    transition-property: color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      margin-right: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-orange);
        text-decoration: underline;
      }
    }
  }

  &__created {
    display: flex;
    justify-content: flex-end;

    svg {
      width: 13.8rem;
      height: 3.5rem;
    }
  }

  @media (max-width: $xl) {
    &__body {
      gap: 10rem;
    }

    &__col {
      &._right {
        padding-right: 0;
      }
    }

    &__wrapper {
      gap: 8rem;
    }
  }

  @media (max-width: $lg) {
    &__body {
      gap: 5rem;
    }

    &__wrapper {
      gap: 4rem;
    }

    &__nav {
      grid-column-gap: 2rem;
    }
  }

  @media (max-width: $md) {
    &__body {
      padding-top: 4rem;
      flex-direction: column;
      align-items: center;
      gap: 0;
    }

    &__rules {
      margin-bottom: 2rem;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-transform: uppercase;
    }

    &__link {
      margin-bottom: 3.5rem;
    }

    &__mail {
      margin-bottom: 2rem;
    }

    &__copyright {
      margin-bottom: 4.5rem;
      text-align: center;
      max-width: 70%;
    }
  }
}
