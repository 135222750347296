.error {
  margin: 9rem 0;

  &__inner {
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__code {
    font-size: 2.2rem;
    color: var(--color-red);
  }

  &__title {
    font-size: 3rem;
    margin-bottom: .5rem;
  }

  &__description {
    font-weight: 300;

    margin-bottom: 3rem;
  }

}