.header-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow: hidden;

  transform: translateX(100%);

  transition-property: transform;
  transition-duration: 0.7s;
  transition-timing-function: ease;

  flex-direction: column;


  &._show {
    transform: translateX(20%);
    transition-duration: var(--duration-long);
  }

  &__inner {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__bg {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .15;

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    &-img {
      width: 100%;
      height: 100%;
    }
  }


  &__burger {
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__body {
    flex-grow: 1;
    height: 1%;
    padding-top: 6.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-x: hidden;
    overflow-y: auto;

    padding-bottom: 16.8rem;
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6.5rem;

    li {
      margin-bottom: 3.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__lang.list-lang {
    margin-bottom: 4 xs .5rem;
  }

  &__item {
    font-size: 1.8rem;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-white);

    &.active {
      color: var(--color-orange);
    }
  }

  &__rules {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-top: 4.5rem;
    text-transform: uppercase;
    color: var(--color-orange);
  }
}
