@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.map {
  width: 100%;
  border-radius: 3.5rem;
  height: 40rem;
  overflow: hidden;

  &._small-fullscreen-btn .gm-fullscreen-control {
    width: 6rem !important;
    height: 6rem !important;
  }

  .gmnoprint,
  .gm-bundled-control {
    display: none;
  }

  .gm-fullscreen-control {
    bottom: 0;
    top: auto !important;
    margin: 2.5rem !important;
    border-radius: 50% !important;
    width: 6.8rem !important;
    height: 6.8rem !important;
    box-shadow: 0 1.1rem 2.5rem rgba(194, 194, 194, 0.55) !important;
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.76835 16.0424H0V24H7.95756V22.2317H3.01503L9.74359 15.5119L8.48806 14.2564L1.76835 20.985V16.0424Z' fill='%23070713'/%3E%3Cpath d='M16.0438 1.76835H20.9864L14.2578 8.48806L15.5133 9.74359L22.2331 3.01503V7.95756H24.0014V0H16.0438V1.76835Z' fill='%23070713'/%3E%3C/svg%3E%0A") !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;

    img {
      display: none !important;
    }
  }

  .gm-ui-hover-effect,
  .gm-style-iw-tc {
    display: none !important;
  }


  .gm-style .gm-style-iw-c,
  .gm-style-iw-d {
    padding: 0;
    overflow: visible !important;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .gm-style-iw-d {
    max-height: none !important;
  }


  &__info {
    background-color: var(--color-white);
    border-radius: 2.5rem;
    padding: 2.5rem 2.5rem 2rem;
    overflow: hidden;
    box-shadow: var(--box-shadow-3);
    border: 2px solid var(--color-orange);

    &-head {
      display: flex;
      justify-content: space-between;

      column-gap: 4.5rem;

      margin-bottom: 1.4rem;
    }

    &-svg {
      width: 3rem;
      height: 2.5rem;
    }

    &-main {
      display: flex;
      flex-direction: column;
    }

    &-name {
      font-weight: 800;
      letter-spacing: 0.025em;
      text-transform: uppercase;
    }

    &-name,
    &-svg {
      color: var(--color-orange);
    }

    &-name,
    &-title {
      font-size: 1.6rem;
      text-transform: uppercase;
    }

    &-title {
      font-weight: 600;
    }

    &-address {
      font-weight: 300;
    }
  }

  &__box {
    margin-top: 1.1rem;

    background-color: var(--color-white);
    border-radius: 9rem;
    padding: .8rem 2.5rem;
    position: relative;
    left: -50%;

    text-align: center;

    &-inner {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }

  .infoBox {
    width: auto;
    overflow: visible !important;

    img {
      display: none;
    }
  }

  .gm-style-iw.gm-style-iw-c {

  }

  @media (max-width: $md) {

    border-radius: 0;

    .gm-fullscreen-control {
      display: none;
    }

    &__modal {
      position: relative;
      padding-top: .3rem;

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 13rem;
        height: .6rem;
        background: var(--color-orange);
        border-radius: 4.7rem;
      }

      &-inner {
        border-radius: 2.5rem 2.5rem 0 0;
        overflow: hidden;
        box-shadow: 0 1.1rem 2.5rem rgba(194, 194, 194, 0.55);
      }

      &-wrap {
        width: 100%;
        background: var(--color-white);

        padding: 3rem 3rem 4.7rem;
      }

      &-head {
        display: flex;
        justify-content: space-between;

        gap: 4rem;

        text-transform: uppercase;
        margin-bottom: 1.1rem;
      }

      &-svg {
        width: 3.6rem;
        height: 3.1rem;

      }

      &-svg,
      &-name {
        color: var(--color-orange);
      }

      &-name {
        font-weight: 800;
        font-size: 1.8rem;
        letter-spacing: 0.025em;
      }

      &-title {
        font-weight: 600;
        font-size: 2rem;
        line-height: 145%;
      }

      &-street {
        font-weight: 300;
        font-size: 2rem;
        line-height: 125%;
      }

    }

  }

}
