@import "./src/assets/scss/breakpoints";
@import "./src/assets/scss/utils";

.map-home {
  margin: 6rem 0 7rem;

  &__map.map {
    height: 50rem;
  }

  &__title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 125%;
    text-transform: uppercase;
    text-align: center;

    margin-bottom: 2.1rem;
  }

  @media(max-width: $md) {
    margin: 6rem 0 6rem;

    &__map.map {
      width: 100vw;
      height: 45.5rem;

      margin: 0 -2.5rem;
    }
  }


}
