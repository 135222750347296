@import '../../assets/scss/breakpoints';

.blog-date {
  display: inline-flex;
  align-items: center;
  background: var(--color-white-1);
  border-radius: 6.9rem;
  font-style: italic;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 135%;
  min-height: 3.4rem;
  padding: 0.5rem 2rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1.1rem;
  }

  @media (max-width: $md) {
    font-weight: 600;
    font-size: 1.2rem;
    padding: 0.5rem 1.9rem;
    min-height: 4.2rem;

    color: white;
    background: var(--color-orange);
  }
}
