.base-preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 18;
  background: fade-out(white, .5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: inherit;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__wrap {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
  }

  &__inner {
    width: 8rem;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      padding-top: 100%;
      display: block;
    }

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    div:after {
      content: "";
      display: block;
      position: absolute;
      width: 8.75%;
      height: 8.75%;
      border-radius: 50%;
      background: var(--color-orange);
      filter: contrast(60%);
      transform: translate(-50%, -50%);
    }

    div:nth-child(1) {
      animation-delay: -0.036s;
    }

    div:nth-child(1):after {
      top: 78.76%;
      left: 78.76%;
    }

    div:nth-child(2) {
      animation-delay: -0.072s;
    }

    div:nth-child(2):after {
      top: 85%;
      left: 70%;
    }

    div:nth-child(3) {
      animation-delay: -0.108s;
    }

    div:nth-child(3):after {
      top: 88.75%;
      left: 60%;
    }

    div:nth-child(4) {
      animation-delay: -0.144s;
    }

    div:nth-child(4):after {
      top: 90%;
      left: 50%;
    }

    div:nth-child(5) {
      animation-delay: -0.18s;
    }

    div:nth-child(5):after {
      top: 88.75%;
      left: 40%;
    }

    div:nth-child(6) {
      animation-delay: -0.216s;
    }

    div:nth-child(6):after {
      top: 85%;
      left: 30%;
    }

    div:nth-child(7) {
      animation-delay: -0.252s;
    }

    div:nth-child(7):after {
      top: 78.76%;
      left: 21.25%;
    }

    div:nth-child(8) {
      animation-delay: -0.288s;
    }

    div:nth-child(8):after {
      top: 70%;
      left: 15%;
    }
  }

  &._global {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;

    width: 100%;

    background-color: transparent;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: fade-out(white, .4);
      filter: blur(3rem);
    }
  }

  &._small {
    .base-preloader {
      &__wrap {
        transform: scale(.4);
      }
    }
  }

}
