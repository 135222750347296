@import './src/assets/scss/breakpoints';

.club-services {
  margin: 10rem 0;
  background: var(--color-white-1);

  &__body {
    display: flex;

    padding: 4.2rem 0 5.7rem;
  }

  &__title {
    margin-right: 14rem;
    margin-bottom: 4rem;
  }

  &__items {
    margin-top: 1.5rem;

    columns: 23.7rem 3;
    column-fill: balance;
    column-gap: 6rem;
  }

  &__item {
    display: flex;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 125%;
    align-items: center;

    page-break-inside: avoid;
    break-inside: avoid;

    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    &-svg {
      margin-right: 2rem;
      width: 2.2rem;
      flex: 0 0 2.2rem;
      height: 1.7rem;
      color: var(--color-green);
    }
  }

  @media (max-width: $lg) {
    &__body {
      display: block;
    }
  }

  @media (max-width: $md) {
    margin: 4.5rem 0 3rem;
    background-color: transparent;

    &__body {
      padding: 0;
    }

    &__items {
      columns: 12rem 2;
      column-gap: 2rem;
    }

    &__item {
      font-size: 1.3rem;
      margin-bottom: 2rem;

      &-svg {
        width: 1.2rem;
        height: 1rem;
        margin-right: 1.5rem;
      }
    }
  }
}
