@import './src/assets/scss/breakpoints';

.one-blog {
  &__ticker {
    margin-bottom: -0.2rem;
    margin-top: 18rem;
  }

  &__btn {
    font-style: italic;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: var(--color-orange);

    svg {
      width: 2.6rem;
      height: 2.1rem;
      margin-left: 1.4rem;
    }
  }

  &__tag {
    display: flex;
    justify-content: center;
  }


  &__inner {

  }

  &__title {
    font-style: italic;
    font-weight: 800;
    font-size: 3rem;
    line-height: 125%;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }

  &__picture {
    float: right;
    width: 57.2rem;
    max-height: 32.8rem;
    margin-left: 8rem;
    margin-bottom: 2rem;
    border-radius: 3.5rem;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 60%;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  &__info {
    &-content {
      display: flex;
      align-items: center;
      gap: 1.1rem;

      margin-bottom: 3.5rem;
    }

    &-text {
      font-weight: 300;
    }

  }


  @media (max-width: $xl) {
    &__picture {
      width: 50rem;
      margin-left: 4rem;
    }
  }

  @media (max-width: $md) {
    margin-bottom: 8rem;

    .promo {
      margin-bottom: 0;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
    }

    &__info {
      &-text {
        margin-bottom: 4rem;
      }
    }

    &__btn {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;

      svg {
        width: 1.6rem;
        height: 1.3rem;
        margin-left: 1.1rem;
      }
    }

    &__picture {
      float: none;
      width: 100vw;
      margin-left: -3rem;
      margin-right: -3rem;
      margin-bottom: 0;
      border-radius: 0;

      &::before {
        padding-top: 57%;
      }
    }

    &__mobile-date {
      position: relative;
      z-index: 2;
      margin: -2.5rem auto 2.2rem;
    }

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 110%;

      margin-bottom: 2.4rem;
    }

    &__tag {
      &-list {
        justify-content: center;
      }
    }

  }
}
