@import "vars";
@import "breakpoints";

.feedback-form {
    @media screen and (max-width: $md) {
        &.open .feedback-form__button {
            display: none;
        }
    }

    .feedback-form__button {
        display: block;
        position: fixed;
        left: 3%;
        bottom: 3%;
        z-index: 100;

        border: 3px solid $colorOrange;
        border-radius: 50%;
        background: white;

        width: 64px;
        height: 64px;

        padding: 15px;


        transition: border 50ms linear;

        &:hover {
            border-width: 5px;
        }

        &-img {
            width: 100%;
        }
    }
}

.feedback-form__container {
    .write-feedback {
        position: relative;

        &__inner {
            @media screen and (max-width: $md) {
                border-radius: 2.5rem;
            }
        }

        &__close {
            position: absolute;
            right: 10px;
            top: -5px;

            @media screen and (min-width: $md) {
                right: 25px;
                top: 5px;
            }

            &:after {
                display: inline-block;
                content: "\00d7"; /* This will render the 'X' */
                font-size: 35px;
            }
        }
    }
}
