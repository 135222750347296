* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: var(--f-base-family);

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

html {
  background: var(--color-white);
  font-size: var(--f-root-size);

  ::selection {
    background-color: var(--color-grey);
    color: var(--color-white);
  }

  @media (max-width: 375px) {
    font-size: 2.667vw;
  }
}

body {
  font-size: var(--f-base-size);
  line-height: var(--f-base-height);
  color: var(--color-black);
  letter-spacing: 0.02em;
  font-family: var(--f-base-family);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  overflow-y: scroll;
  overflow-x: hidden;

  -webkit-overflow-scrolling: touch;

  &._scroll-lock {
    overflow: hidden;
  }
}

#root {
  transition-property: transform;
  transition-duration: var(--duration-long);
  transition-timing-function: ease-out;
  width: 100%;

  &._show-menu {
    transform: translateX(-20%);
    transition-timing-function: ease-in;

    will-change: transform;
  }
}

a {
  display: inline-block;
  outline: 0;
  text-decoration: none;
  color: inherit;
}

button {
  background: none;
  outline: 0;
  cursor: pointer;
  color: inherit;
}

input,
textarea {
  outline: 0;
  -webkit-appearance: none;
}

img {
  user-select: none;
  pointer-events: none;
}

ul,
ol {
  list-style: none;
}

img,
picture,
svg {
  display: block;
}

svg {
  fill: currentColor;
}

input[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='text']::-ms-clear,
input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
