@import './src/assets/scss/breakpoints';

.blog-page {
  &__buttons {
    display: flex;
  }

  &__btn {
    font-style: italic;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: var(--color-black);
    padding: .7rem 2rem;
    border-radius: 3.6rem;
    margin-right: 2rem;
    border: 1px solid var(--color-orange);
    background: var(--color-orange);

    //&:nth-child(2) {
    //  background: #cd6c74;
    //}
    //
    //&:nth-child(3) {
    //  background: #a36893;
    //}
    //
    //&:nth-child(4) {
    //  background: var(--color-purple-2);
    //}

    &.active {
      color: var(--color-orange);
      background: transparent;

      //&:nth-child(2) {
      //  color: #cd6c74;
      //  border-color: #cd6c74;
      //}
      //
      //&:nth-child(3) {
      //  color: #a36893;
      //  border-color: #a36893;
      //}
      //
      //&:nth-child(4) {
      //  color: var(--color-purple-2);
      //  border-color: var(--color-purple-2);
      //}
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__ticker {
    margin-bottom: -0.2rem;
  }

  .paginate {
    margin-bottom: 12rem;
  }

  .promo {
    &__inner {
      flex-wrap: wrap;
    }
  }

  @media (max-width: $md) {
    .promo {
      margin-bottom: 0;
      z-index: 2;

      &::before {
        display: none;
      }

      .container {
        position: static;
      }
    }

    .paginate {
      margin-bottom: 7.8rem;
    }

    &__wrapper {
      position: absolute;
      z-index: 2;
      top: 5.1rem;
      left: -2.5rem;
      right: -2.5rem;
      width: 100vw;
      background-color: black;

      &-buttons {
        max-height: 0;
        overflow: hidden;

        transition-property: max-height;
        transition-duration: var(--duration-long);
        transition-timing-function: var(--easing);
      }

      &.show {
        .blog-page__wrapper-buttons {
          max-height: 35rem;
        }

        .blog-page__buttons {
          opacity: 1;
        }
      }
    }

    &__buttons {
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 3.6rem;
      opacity: 0;

      transition-property: opacity;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }

    &__btn {
      margin-right: 0;
      margin-bottom: 1.5rem;
      max-width: 20rem;
      width: 100%;
      display: flex;
      justify-content: center;

      &-close {
        margin-top: 1.3rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        display: flex;
        align-items: center;
        text-align: right;

        color: white;

        svg {
          width: 1.2rem;
          height: 1.2rem;
          margin-left: 1rem;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__filter {
      display: flex;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      align-items: center;
      text-transform: uppercase;
      color: var(--color-orange);

      svg {
        width: 2.1rem;
        height: 1.7rem;
        margin-left: 1rem;
      }
    }
  }
}
