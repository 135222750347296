@import '../../../assets/scss/breakpoints';

.smart-fit {
  margin: 9rem 0 11rem;

  &__body {
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
  }

  &__title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 125%;

    margin-bottom: 2.5rem;

    span {
      display: block;
      font-weight: 800;
      font-size: 3rem;
      line-height: 125%;
      color: var(--color-green);
    }
  }

  &__text {
    font-weight: 300;
    line-height: 152.02%;
    margin-bottom: 3rem;
  }

  &__left {
    width: 34%;
    margin-right: 5rem;
  }

  &__right {
    position: relative;
    width: 71%;
    min-height: 36.5rem;
    padding: 5rem;

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3.5rem;
    }

    &-girl {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 26.4rem;
    }

    &-content {
      position: relative;
      z-index: 1;
      max-width: 31.5rem;
    }

    &-title {
      font-weight: 500;

      color: var(--color-white);
      margin-bottom: 2rem;
    }

    &-title,
    &-phone {
      font-size: 1.6rem;
      line-height: 125.5%;
    }

    &-phone {
      font-weight: 800;

      color: var(--color-white);
      display: flex;
      align-items: center;
      margin-bottom: 2.8rem;

      svg {
        width: 2rem;
        height: 1.8rem;
        margin-right: 1.6rem;
      }
    }
  }

  &__svg {
    width: 15.6rem;
    height: 7.9rem;
    color: var(--color-white);
  }

  &__slider {
    width: 100%;
  }

  &__slide {
    position: relative;
    flex: 0 0 calc(25% - 2.3rem);
    padding-top: 15%;
    border-radius: 2.5rem;
    overflow: hidden;
    margin-right: 3rem;

    &:last-child {
      margin-right: 0;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &-prev {
      left: -1rem;
    }

    &-next {
      right: -1rem;
    }
  }

  @media (max-width: $lg) {
    &__body {
      display: block;
    }

    &__left {
      width: 100%;
      margin-bottom: 4rem;
    }

    &__right {
      width: 100%;
    }
  }

  @media (max-width: $md) {
    margin: 6rem 0 6rem;

    &__top {
      padding-top: 2.4rem;
      background: var(--color-orange-2);
    }

    &__body {
      padding-bottom: 5rem;
    }

    &__left {
      color: var(--color-white);
      padding-left: 2rem;
      //width: 70%;
    }

    &__svg {
      width: 12.9rem;
      height: 6.5rem;

      margin-bottom: 3rem;
    }

    &__title {
      font-size: 1.4rem;
      font-weight: 700;
      text-transform: uppercase;

      margin-bottom: 1rem;
    }

    &__text {
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 135%;

      margin-bottom: 2.3rem;
    }

    &__more {
      &-text {
        display: inline-block;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: calc(100% + 1px);
          left: 0;
          width: 100%;
          border-bottom: 1px solid currentColor;
        }
      }

      &.button-arrow-right._orange {
        color: var(--color-white);

        svg {
          color: inherit;
        }
      }

    }


    &__body {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &__right {
      padding: 0;
      min-height: auto;
      position: static;

      height: 26.5rem;

      &-bg {
        position: relative;
        width: 100%;
        left: 50%;
        transform: translateX(-57%);
        border-radius: 0;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(178.81deg, #F9BC25 1.02%, rgba(249, 188, 37, 0) 9.49%);
        }
      }

      &-girl {
        width: 23rem;
        right: -2rem;
      }
    }
  }

  @media (max-width: $xs) {
    &__right {
      &-bg {

      }

      &-girl {
        width: 19.4rem;
        right: -4rem;
      }

    }
  }

}
