@import './src/assets/scss/breakpoints';

.club-page {
  margin-bottom: 10rem;

  .promo {
    &__inner {
      flex-wrap: wrap;
    }
  }

  &__status {
    border-radius: 1rem;
    font-weight: 700;
    text-transform: uppercase;

    padding: 1.1rem 7.4rem;

    background: var(--color-orange);
    box-shadow: 0 .5rem 1.5rem rgba(255, 166, 33, 0.35);

    &._red {
      background: var(--color-red-2);
      box-shadow: 0 .5rem 1.5rem rgba(255, 63, 44, 0.55);
    }
  }

  @media (max-width: $md) {
    margin-bottom: 8rem;

    .promo {
      display: none;
    }

    &__title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 125%;
      text-transform: uppercase;
      margin-bottom: 2rem;

      &._mt-20 {
        margin-top: 3rem;
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;

      &-wrap {
        margin-bottom: 4.7rem;
      }
    }

    &__btn {
      margin-right: 1.8rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &__status {
      border-radius: 0;
      text-align: center;
      color: white;
      padding: 1.2rem 2rem;
    }

  }
}
