@import "./src/assets/scss/breakpoints";
@import "./src/assets/scss/utils";

.price-banner {
  position: relative;
  margin-top: 3rem;
  border-radius: 1.5rem;
  overflow: hidden;
  min-height: 13.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 145%;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-white);

    span {
      margin-top: 0.2rem;
      display: block;
      font-weight: 800;
      font-size: 2.4rem;
      text-transform: uppercase;
    }
  }

  @media (max-width: $md) {
    min-height: auto;

    &__title {
      font-weight: 600;
      font-size: 1.4rem;
      padding: 3.5rem 1.5rem;

      span {
        margin-top: 0.5rem;
        font-size: 2rem;
      }
    }

  }

}
