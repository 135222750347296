@import '../../../assets/scss/breakpoints';

.corporate-benefits {
  display: flex;
  justify-content: space-between;

  &__item {
    position: relative;
    width: 8.5rem;

    &:last-of-type {
      width: auto;
    }
  }

  &__icon {
    background: var(--color-black);
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    svg {
      height: 2.6rem;
      width: 2.6rem;
      color: var(--color-orange);
    }
  }

  &__text {
    font-weight: 300;
    line-height: 125%;
    width: 17rem;
  }

  &__dots {
    position: relative;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    background: var(--color-orange);
    margin-top: 3.7rem;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 0.7rem;
      height: 0.7rem;
      background: var(--color-orange);
      border-radius: 50%;
    }

    &::before {
      left: -1.7rem;
    }

    &::after {
      right: -1.7rem;
    }
  }

  &__slider {
    margin: 0 auto;
    overflow: visible;
    position: relative;
  }

  &__slide {
    flex-shrink: 0;
    width: 100%;
  }

  &__btn {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);

    &-prev {
      left: 5.5%;

      svg {
        transform: rotate(-180deg);
      }
    }

    &-next {
      right: 5.5%;
    }

    svg {
      width: 0.8rem;
      height: 1.2rem;
      color: var(--color-orange);
    }
  }

  @media (max-width: 1250px) {
    &__text {
      width: 17rem;
    }
  }

  @media (max-width: $md) {
    &__slider {
      width: 20rem;
    }

    &__slide.swiper-slide-active &__text {
      opacity: 1;
    }

    &__slide.swiper-slide-active  &__icon{
      transform: scale(1);
    }

    &__slide.swiper-slide-prev &__icon {
      transform: translate(6rem, 0) scale(.65);
    }

    &__slide.swiper-slide-next &__icon {
      transform: translate(-6rem, 0) scale(.65);
    }

    &__icon {
      transform: translate(0, 0) scale(.65);
    }

    &__icon {
      transition-property: transform;
      transition-duration: var(--duration-long);
      transition-timing-function: var(--easing);
    }


    &__item {
      width: 100%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      &:last-of-type {
        width: 100%;
      }
    }

    &__text {
      font-size: 1.6rem;
      opacity: 0;
      max-width: 30rem;
      text-align: center;
      width: 100%;

      transition-property: opacity;
      transition-duration: var(--duration-long);
      transition-timing-function: var(--easing);
    }

    &__wrapper {
      top: 4.2rem;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      max-width: 18.5rem;
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__dots {
      position: absolute;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);

      &-next {
        right: 0;

        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      &-prev {
        left: 0;

        &:after {
          content: none;
        }

        &::before {
          display: none;
        }
      }
    }
  }
}
