@import './src/assets/scss/breakpoints';
.club-slider {
  position: relative;
  margin: 6rem 0 10rem;

  &__slide {
    cursor: pointer;
    position: relative;
    flex: 0 0 calc(25% - 2.3rem);
    border-radius: 2.5rem;
    padding-top: 15%;
    margin-right: 3rem;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &-prev {
      left: -1rem;
    }

    &-next {
      right: -1rem;
    }
  }

  @media (max-width: $md) {
    margin: 0 0 3.5rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      background: linear-gradient(
        180deg,
        rgba(7, 7, 19, 0) 41.67%,
        #070713 100%
      );
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    .container {
      padding: 0;
    }

    &__slide {
      flex: 0 0 100%;
      border-radius: 0;
      padding-top: 58%;
      margin-right: 0;
    }

    &__btn {
      display: none !important;
    }
  }
}
