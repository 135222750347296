.guide {
  &__h1 {
    font-size: 3rem;
    color: black;
    margin: 0 0 1rem 0;
  }

  &__list {
    margin: 4rem 0;
  }

  &__item {
    padding-bottom: 2rem;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 2rem;
  }

  &__buttons {
    &-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-description {
      margin-left: 1rem;
    }
  }

  .svg-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &__item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;

      background-color: fade-out(white, 0.6);

      svg {
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}
