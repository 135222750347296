.base-checkbox {
  display: flex;

  &__item {
    font-style: italic;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    text-transform: uppercase;
    color: var(--color-orange);

    transition-property: color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

    &-mobile {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-transform: uppercase;
      color: var(--color-orange);

      svg {
        width: 1.9rem;
        height: 1.9rem;
        margin-left: 1.1rem;
      }
    }

    &._left {
      color: var(--color-white);
    }
  }

  &__toggle {
    position: relative;
    border-radius: 3.1rem;
    background: var(--color-orange);
    width: 4rem;
    height: 2rem;
    margin: 0 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.4rem;
      width: 1.4rem;
      height: 1.4rem;
      background: var(--color-black);
      border-radius: 50%;

      transition-property: left;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }
  }

  &.active {
    .base-checkbox__item {
      &._left {
        color: var(--color-orange);
      }

      &._right {
        color: var(--color-white);
      }
    }

    .base-checkbox__toggle {
      &::before {
        left: 2.2rem;
      }
    }
  }
}
