@import './src/assets/scss/breakpoints';

.welcome {
  position: relative;
  background-color: black;
  border-radius: 0 0 7rem 7rem;
  overflow: hidden;
  padding-top: 15rem;
  margin-top: -15rem;

  &__body {
    padding: 6.6rem 0 15.4rem;
  }

  &__inner {
    z-index: 2;
  }

  &__title {
    color: var(--color-white);
  }

  &__girl {
    position: absolute;
    bottom: 0;
    right: 21.7rem;
    z-index: 3;
    width: 38.7rem;
  }

  &__man {
    position: absolute;
    bottom: 0;
    z-index: 2;
    right: -4.8rem;
    width: 41.8rem;
  }

  &__gym {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-img {
      opacity: 0.15;
      width: 100%;
      height: 100%;
    }
  }

  &__bg {
    position: absolute;
    z-index: 3;
    border-radius: 50%;

    pointer-events: none;

    &-1 {
      top: -44rem;
      right: -51rem;

      width: 62.5rem;
      height: 62.5rem;
      background: radial-gradient(50% 50% at 50% 50%, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
      opacity: .15;
    }

    &-2 {
      z-index: 1;
      right: -30rem;
      bottom: -20rem;

      width: 130rem;
      height: 68.8rem;
      filter: blur(12.75rem);
      background: radial-gradient(62.64% 32.9% at 51.27% 50.38%, #B0B0B0 0%, rgba(128, 128, 128, 0) 100%);
      opacity: .35;
    }

    &-3 {
      z-index: 1;
      right: -46rem;
      bottom: -44rem;

      width: 137rem;
      height: 137rem;

      background: radial-gradient(50% 50% at 50% 50%, #C1C1C1 0%, rgba(154, 154, 154, 0) 100%);
      transform: rotate(-15deg);
      opacity: .15;
    }

  }

  &__visitors {

    display: flex;
    column-gap: 3.8rem;
    align-items: center;

    margin-bottom: 4.7rem;

    &-img {
      position: relative;
      display: flex;

      img {
        width: 6.3rem;
        height: 6.3rem;
        margin-left: -2rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &-plus {
      position: absolute;
      top: 0.1rem;
      right: -.8rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: var(--color-white);
      box-shadow: 0 1.1rem 2.5rem rgba(0, 0, 0, 0.75);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1rem;
        height: 1rem;
        color: var(--color-purple);
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      margin-top: 0.8rem;
    }

    &-stars {
      display: flex;
    }

    &-info {
      font-style: italic;
      line-height: 160%;
      color: var(--color-white);
      max-width: 36rem;
    }

    &-stars {
      margin-right: 2.1rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.8rem;
        color: var(--color-orange);
        filter: drop-shadow(var(--box-shadow));

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    column-gap: 4rem;
    align-items: center;
  }

  &__button {
    &-photo {
      display: flex;
      align-items: center;
      column-gap: 1.7rem;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 145%;
      text-transform: uppercase;
      color: var(--color-white);
    }

    &-photo:hover &-svg {
      svg {
        transform: scale(1.4);
      }
    }

    &-svg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 2px solid var(--color-white);

      svg {
        width: .9rem;
        height: 1.1rem;
        color: var(--color-orange);
        filter: drop-shadow(var(--box-shadow));

        transition-property: transform;
        transition-duration: var(--duration);
        transform-origin: left center;
        transition-timing-function: var(--easing);
      }
    }
  }

  //@media (max-width: 1450px) {
  //  &__girl {
  //    width: 45rem;
  //    right: 20rem;
  //  }
  //}

  //@media (max-width: $xl) {
  //  &__body {
  //    padding: 5rem 0 10rem;
  //  }
  //
  //  &__man {
  //    right: -10.5rem;
  //    width: 46.2rem;
  //  }
  //
  //  &__girl {
  //    width: 35rem;
  //    right: 17rem;
  //  }
  //}

  @media (max-width: 1250px) {
    border-radius: 0;

    &__body {
      padding: 3rem 0 10rem;
    }

    &__title {
      margin-bottom: 2.5rem;
    }

    &__visitors {
      flex-direction: column;
      align-items: flex-start;
      gap: 2.5rem;
      margin-bottom: 3.5rem;
    }

    &__buttons {
      gap: 3rem;
    }
  }

  @media (max-width: $md) {
    &__body {
      padding: 0.8rem 0 35.8rem;
    }

    &__title {
      margin-bottom: 1.9rem;
      width: 80%;
    }

    &__visitors {
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.5rem;

      gap: 2.2rem;

      &-img {
        margin-left: 1.4rem;

        img {
          width: 5.3rem;
          height: 5.3rem;
          margin-left: 0;
        }
      }

      &-plus {
        width: 1.7rem;
        height: 1.7rem;
        right: -0.6rem;
        top: 0.1rem;

        svg {
          width: 0.7rem;
          height: 0.7rem;
        }
      }

      &-info {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 135%;
      }

      &-bottom {
        font-style: italic;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 160%;
        color: var(--color-white);

        margin-left: 1rem;
      }
    }

    &__button {
      &-btn.button {
        position: absolute;
        z-index: 5;
        left: 50%;
        transform: translateX(-50%);
        bottom: 5rem;
      }
    }

    &__bg {

      &-1 {
        top: inherit;
        bottom: -24.5rem;
        right: -34rem;
        background: radial-gradient(62.64% 32.9% at 51.27% 50.38%, #B0B0B0 0%, rgba(128, 128, 128, 0) 100%);
        width: 53.5rem;
        height: 53.5rem;
        transform: rotate(-15deg);
      }


      &-2 {
        right: -21rem;
        bottom: -17.3rem;

        width: 80.7rem;
        height: 43rem;
        background: radial-gradient(62.64% 32.9% at 51.27% 50.38%, #B0B0B0 0%, rgba(128, 128, 128, 0) 100%);
      }

      &-3 {
        right: -25rem;
        bottom: 32rem;

        width: 85.8rem;
        height: 85.8rem;
      }
    }

    &__girl {
      width: 23.7rem;
      left: 58%;
      transform: translate(-83%);
    }

    &__man {
      right: 42.7%;
      transform: translateX(73%);
      width: 27rem;
    }

    &__gym {
      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      }

      &-img {
        opacity: .2;
      }
    }

  }
}
