@import './src/assets/scss/breakpoints';
.trainer-list {
  margin-bottom: 3rem;

  &__title {
    font-style: italic;
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 145%;
    text-transform: uppercase;
    color: var(--color-orange);
    margin-bottom: 0.6rem;
  }

  &__item {
    display: flex;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 152.02%;

    span {
      flex: 0 0 0.4rem;
      display: block;
      width: 0.4rem;
      height: 0.4rem;
      background: var(--color-black);
      border-radius: 50%;
      margin: 1.1rem 1.1rem 0;
    }
  }

  @media (max-width: $md) {
    &__item {
      font-size: 1.4rem;

      span {
        margin-top: 0.9rem;
        margin-left: 0.4rem;
      }
    }
  }
}
