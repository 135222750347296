@import "./src/assets/scss/utils";

.regulations {

  &__inner {
    margin-bottom: 15rem;
  }

  &__btn {
    color: var(--color-orange);
  }

}
