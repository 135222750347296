.slider-pagination {
  position: absolute;
  z-index: 2;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  .swiper-pagination-bullet {
    position: relative;
    width: 0.9rem;
    height: 0.9rem;
    border: 1px solid var(--color-white);
    border-radius: 50%;
    margin-right: 0.7rem;

    transition-property: border-color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.5rem;
      height: 0.5rem;
      background: var(--color-white);
      border-radius: 50%;
      opacity: 0;

      transition-property: opacity;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }

    &:last-child {
      margin-right: 0;
    }

    &-active {
      border-color: transparent;

      &::before {
        opacity: 1;
      }
    }
  }
}
