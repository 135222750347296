@import './src/assets/scss/mixins';
@import "src/assets/scss/breakpoints";

.list-lang {
  @include resetList;
  display: flex;

  column-gap: 1rem;

  &__item {
    flex-shrink: 0;

    display: flex;
  }

  &__btn {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px solid transparent;
    border-radius: 50%;

    transform: scale(.5);

    transition-property: transform, border-color;
    transition-duration: var(--duration);
    transition-timing-function: var(--easing);
    opacity: .45;

    &._active {
      opacity: 1;
      transform: scale(1);
      border-color: var(--color-white);
      pointer-events: none;
    }

    &._active &-img {
      width: 60%;
      height: 60%;
    }

    &-img {
      width: 100%;
      height: 100%;

      border-radius: inherit;
    }
  }

  @media (max-width: $md) {
    column-gap: 2rem;
    &__btn {
      width: 5.5rem;
      height: 5.5rem;
    }
  }


}
