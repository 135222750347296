@import './src/assets/scss/breakpoints';
@import './src/assets/scss/utils';

.trainer {
  margin-bottom: 13.5rem;

  &._mod {
    .trainer {

      &__title {

      }

      &__description {
        color: var(--color-orange);
        font-weight: 800;
        font-size: 1.6rem;

        margin-top: 1.5rem;
      }
    }

    .trainer-list {
      &__title {
        display: none;
      }

      &__item {
        margin-bottom: .5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

    }
  }

  &__body {
    display: flex;
    align-items: flex-start;
  }

  &__left {
    flex-basis: 57.5rem;
    position: relative;
    border-radius: 2.5rem;
    overflow: hidden;
    margin-right: 3rem;

    display: flex;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 80%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__right {
    flex-basis: 57rem;
  }

  &__name {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 145%;
    text-transform: uppercase;
  }

  &__title {
    font-style: italic;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 145%;
    text-transform: uppercase;
  }

  &__description {
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
  }

  @media (max-width: $lg) {
    &__left {
      margin-right: 5rem;
      flex: 1 1 45%;
    }
  }

  @media (max-width: $md) {
    margin-bottom: 10rem;

    &__body {
      display: block;
    }

    &__left {
      height: 30rem;
      margin: 0 -3rem 4rem;
      border-radius: 0;

      &:before {
        content: none;
      }
    }

    &__position {
      color: var(--color-orange);
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: uppercase;

      margin-bottom: 1rem;
    }

    &__title,
    &__description {
      text-transform: none;
      font-size: 1.6rem;
    }

    &__title {
      margin-bottom: 1rem;
    }

    &__description {
      margin-bottom: 3rem;
    }

  }
}
