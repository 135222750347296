@import './src/assets/scss/mixins';
@import './src/assets/scss/breakpoints';

.photo {
  // background: var(--color-black);
  min-height: calc(100vh - 35rem);

  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 700;
    font-size: 1.8rem;
    font-style: italic;
    text-transform: uppercase;
    color: var(--color-orange);
  }

  &__list {
    @include resetList();
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 3rem 3rem;

    margin-bottom: 13.5rem;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &-wrap {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      border-radius: 2.5rem;

      background: fade-out(#EFEFEF, .45);
      border: 2px solid fade-out(#EFEFEF, .45);

      padding: 1.5rem 2rem;

      transition-property: border-color;
      transition-duration: var(--duration-long);
      transition-timing-function: var(--easing);
    }

    @media(min-width: $md + 1) {
      &-wrap:hover {
        border-color: var(--color-orange);
      }
    }


    &-top {
      margin-bottom: 3.2rem;

      display: flex;
      flex-direction: column;
    }

    &-content {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }

    &-picture {
      flex-shrink: 0;
      width: 5.5rem;
      height: 5.5rem;

      border-radius: 50%;
      overflow: hidden;
      margin-right: 1.5rem;
      padding: .2rem;
      background-color: var(--color-orange);
    }

    &-img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      border-radius: inherit;

      transition-property: transform;
      transition-duration: var(--duration);
      transition-timing-function: var(--easing);
    }

    &-info {
      flex: 1 1 0;
      width: 1%;
    }

    &-name,
    &-description {
      font-size: 1.6rem;
      text-transform: uppercase;
      max-width: 100%;
    }

    &-name {
      font-weight: 800;
      line-height: 1.2;
      color: var(--color-orange);
    }

    &-description {
      font-weight: 600;
      line-height: 145%;
    }

    &-street {
      font-weight: 300;
      line-height: 125%;
    }

    &-btn {
      @include resetBtn;
      align-items: center;

      font-weight: 500;
      font-size: 1.4rem;
      text-transform: uppercase;
      margin-top: auto;
      line-height: 1;


      &-svg {
        flex-shrink: 0;
        width: .9rem;
        height: 1.4rem;
        color: var(--color-orange);
        margin-left: 1rem;
        margin-bottom: .2rem;

        transition-property: transform;
        transition-duration: var(--duration);
        transition-timing-function: var(--easing);
      }
    }

  }

  &__ticker {
    margin-top: auto;
  }

  @media(max-width: $md) {
    min-height: auto;

    .container {
      padding: 0;
      margin: 0;
    }

    .promo__title.title {
      padding: 0 2.5rem;
    }

    &__list {
      display: block;

      margin-bottom: 3.1rem;
    }

    &__list,
    &__item {
      margin-top: 0;
      margin-right: 0;
    }

    &__item {
      width: 100%;
      position: relative;
      padding-bottom: 3rem;
      margin-bottom: 3.2rem;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;

        &:before {
          content: none;
        }
      }

      &:before {
        content: '';

        position: absolute;
        right: -2.5rem;
        left: -2.5rem;
        bottom: 0;
        height: .3rem;
        background: linear-gradient(90deg, #FFA621 0%, #FC714F 100%);
      }

      &-wrap {
        box-shadow: none;
        border-radius: 0;
        padding: 0 3rem;
        background-color: transparent;
        border: none;
      }

      &-content {
        margin-bottom: 1rem;
      }

      &-street {
        font-weight: 400;
      }

      &-top {
        margin-bottom: 1.7rem;
      }
    }
  }
}
