.schedule-table {
  &__row {
    display: grid;
    align-items: center;
    grid-template-columns:  3.1fr  1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }

  &__top {
    background: var(--color-orange);
    border-radius: 1.5rem;
    min-height: 6.5rem;

    &-item {
      font-weight: 800;
      font-size: 2rem;
      line-height: 145%;
      text-transform: uppercase;
      color: var(--color-white);

      &:first-child {
        padding-left: 4rem;
      }
    }
  }

  &__item {
    font-size: 2rem;
    line-height: 145%;
    text-transform: uppercase;
    padding: 3rem 0;
    border-bottom: .2rem solid var(--color-white-1);

    &-item {

      &:first-child {
        padding-left: 4rem;
      }

      &._start {
        align-self: flex-start;
      }

      span {
        display: block;
      }
    }

    &-recruiting {
      flex-shrink: 0;
      font-weight: 700;
      font-size: 1rem;
      line-height: 160%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      background: var(--color-green);
      border-radius: 1rem;
      min-height: 3.4rem;
      color: var(--color-white);

      padding: .9rem 1.5rem;
    }

    &-title {
      font-style: italic;
      font-size: 1.6rem;
      font-weight: 600;

      display: flex;
      align-items: center;

      column-gap: 3rem;
    }
  }
}
