@import '../../assets/scss/breakpoints';
.ticker {
  position: relative;
  z-index: 2;
  background: linear-gradient(90deg, #FFA621 0%, #FC714F 100%);

  &._mb-2 {
    margin-bottom: -.3rem;
  }

  &__inner {
    display: flex;
    align-items: center;
    padding: .7rem 0;
  }

  &__text {
    font-size: 1.8rem;
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0 3rem;
  }


  &__svg {
    width: 1.1rem;
    height: 1.8rem;
  }

  @media (max-width: $md) {

    &__inner {
      padding: .7rem;
    }

    &__svg {
      width: 2rem;
      height: 2rem;
    }

    &__text {
      font-size: 1.2rem;

      margin: 0 3rem;
    }

  }
}
